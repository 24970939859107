@charset "utf-8";

$family-primary: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$family-secondary: Merriweather, Georgia, "Times New Roman", Times, serif;
$navbar-height: 5rem;
$navbar-item-img-max-height: 3rem;

@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/helpers/_all.sass";

$navbar-background-color: $white-bis;
$navbar-dropdown-background-color: $white-bis;
$navbar-dropdown-border-top: none;
$navbar-dropdown-arrow: $grey-dark;

@import "~bulma/sass/components/_all.sass";
@import "~bulma/sass/elements/_all.sass";
@import "~bulma/sass/grid/_all.sass";
@import "~bulma/sass/layout/_all.sass";

@font-face {
  font-family: Merriweather;
  src: url("~assets/fonts/Merriweather/Merriweather_Regular.ttf");
}

@font-face {
  font-family: "Open Sans";
  src: url("~assets/fonts/Open_Sans/OpenSans_Regular.ttf");
}

@font-face {
  font-family: "Open Sans";
  src: url("~assets/fonts/Open_Sans/OpenSans_Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Open Sans";
  src: url("~assets/fonts/Open_Sans/OpenSans_Light.ttf");
  font-weight: lighter;
}

.img-icon {
  align-items: center;
  border-radius: 50%;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 30px;
  opacity: 0.8;
  padding: 20px;

  height: 230px;
  width: 230px;
}

.img-icon:hover {
  background: rgba(245,245,245,0.5);
  box-shadow: 1px 2px 3px rgba(0,0,0,0.4);
  border: 1px solid #000;
  opacity: 1;
}
